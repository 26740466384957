import PERSON from '../../../../assets/icons/person.svg'
import FLAG from '../../../../assets/icons/flag.svg'
import MAIL from '../../../../assets/icons/mail.svg'
import { UserData } from '../../../../utils/types'
import { auth } from '../../../../utils/api/firebase'
import { signOut } from 'firebase/auth'

interface PreviewProps {
  userData: UserData
  giftAid: boolean
  handlePay: () => void
}

const Preview = ({ userData, giftAid, handlePay }: PreviewProps) => {
  const { district, firstName, lastName, email } = userData

  return (
    <div>
      <h1 className="text-2xl mb-12 font-semibold">Review & pay</h1>

      <div className="flex flex-col md:flex-row mb-12">
        <div className="flex-1 mb-12 sm:mb-0">
          <div className="flex mb-3">
            <img className="mr-3" src={PERSON} alt="person" />
            <p className="whitespace-nowrap">
              {firstName} {lastName}
            </p>
          </div>
          <div className="flex mb-3">
            <img className="mr-3" src={FLAG} alt="flag" />{' '}
            <p>{district.name}</p>
          </div>
          <div className="flex mb-3">
            <img className="mr-3" src={MAIL} alt="mail" /> <p>{email}</p>
          </div>
          <div className="border-t border-gray-200 pt-4 mt-4 mr-4">
            <p className="font-bold">Delivery address</p>
          </div>
          <div>
            <p>{userData.address?.country}</p>
            <p>
              {userData.address?.postcode}, {userData.address?.city}
            </p>
            <p>{userData.address?.streetAddress}</p>
          </div>
        </div>

        <dl className="flex-1 text-sm font-medium text-gray-500 space-y-6">
          <div className="flex justify-between">
            <dt>Donation amount</dt>
            <dd className="text-gray-900">£20.00</dd>
          </div>

          {giftAid && (
            <div className="flex justify-between">
              <dt>Gift Aid*</dt>
              <dd className="text-gray-900">£5.00</dd>
            </div>
          )}

          <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
            <dt className="text-base">Total payable</dt>
            <dd className="text-base">£20.00</dd>
          </div>
        </dl>
      </div>

      <div className="flex flex-col md:flex-row gap-4 justify-between items-center border-t mt-6 border-gray-200 pt-6">
        {giftAid && (
          <p className="text-sm max-w-sm text-primary">
            *You've donated £20 to take part in Doddie Aid, with the UK
            Government adding an extra £5 (25%) as part of the GiftAid scheme.
          </p>
        )}
        <div className="flex gap-3">
          <button onClick={() => signOut(auth)} className="text-error mr-12">
            Logout
          </button>
          <button onClick={handlePay} className="button-primary w-60">
            Pay £20.00
          </button>
        </div>
      </div>
    </div>
  )
}

export default Preview
