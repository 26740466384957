import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Outlet } from 'react-router'
import Steps from '../../pages/Steps'
import ThankYou from '../../pages/ThankYou'
import { API } from '../../utils/api/endpoints'
import { userRef } from '../../utils/api/firebase/functions/user'
import ToastContext from '../../utils/context/toastContext'
import UserTokenContext from '../../utils/context/UserTokenContext'
import { SystemData, UserData } from '../../utils/types'
import { Navigate, useLocation } from 'react-router-dom'
import { systemConstantsRef } from '../../utils/api/firebase/functions/system'
import Spinner from '../Spinner'

interface ShowStepsProps {
  uid: string
}

const ShowSteps = ({ uid }: ShowStepsProps) => {
  const [user, loading, error] = useDocument(userRef(uid))
  const [system, systemLoading] = useDocument(systemConstantsRef)

  const { token } = useContext(UserTokenContext)
  const { pathname } = useLocation()

  const { setToast } = useContext(ToastContext)

  const [timestamp, setTimestamp] = useState('')
  useState(() => {
    const getTimeStamp = async () => {
      const request = await axios({
        method: 'GET',
        url: API.system.timestamp,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (request.data && request.data.timestamp) {
        setTimestamp(request.data.timestamp)
      }
    }
    getTimeStamp()
    // @ts-ignore
  }, [])

  useEffect(() => {
    if (error) {
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: error.message,
      })
    }
  }, [error, setToast])

  const systemData = system?.data() as SystemData
  const startDate = systemData?.campaign?.startDate.toDate()
  const endDate = systemData?.campaign?.endDate.toDate()
  const differenceEnd = +new Date(endDate) - +new Date()
  const differenceStart = +new Date(startDate) - +new Date()

  const userData = user?.data() as UserData
  const { hasPayedOnBoarding, district } = userData || {}

  if (loading || !timestamp || systemLoading) {
    return <Spinner />
  }

  // If the campaign ends, redirect the user to thank you screen
  if (pathname !== '/thank-you' && differenceEnd <= 0) {
    return <Navigate replace to="/thank-you" />
  }

  if (
    pathname !== '/thank-you' &&
    differenceStart >= 0 &&
    hasPayedOnBoarding &&
    district
  ) {
    return <Navigate replace to="/thank-you" />
  }

  // If pathname is thank-you but campaign ended show Thank you screen rather the diving into Steps/Outlet
  if (pathname === '/thank-you' && differenceEnd <= 0) {
    return <ThankYou uid={uid} />
  }
  if (!hasPayedOnBoarding || !district) {
    return (
      <Steps
        userData={userData}
        startingPoint={!district ? 1 : userData.address ? 4 : 2}
      />
    )
  }

  return <Outlet />
}

export default ShowSteps
