import { District } from '../types'

type Action =
  | { type: 'SET_ERROR'; error: string }
  | { type: 'SET_LOADING'; loading: boolean }
  | { type: 'SET_DISTRICT'; district: District | null }
  | { type: 'SET_COUNTDOWN'; countdown: Countdown }
  | { type: 'SET_CAMPAIGN_STATUS'; status: CampaignStatus }

export interface ThankYouReducerProps {
  loading: boolean
  error: string
  district: District | null
  countdown: Countdown
  status: CampaignStatus
}

export type CampaignStatus = 'WAITING' | 'START' | 'END'

type Countdown = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const ThankYouReducer = (state: ThankYouReducerProps, action: Action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.loading }
    case 'SET_ERROR':
      return { ...state, error: action.error }
    case 'SET_DISTRICT':
      return { ...state, district: action.district }
    case 'SET_COUNTDOWN':
      return { ...state, countdown: action.countdown }
    case 'SET_CAMPAIGN_STATUS':
      return { ...state, status: action.status }
    default:
      throw new Error('Wrong action type')
  }
}
