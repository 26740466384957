import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import AuthTemplate from '../../components/AuthTemplate'
import * as Yup from 'yup'
import Input from '../../components/Input'
import axios from 'axios'
import { API } from '../../utils/api/endpoints'
import ToastContext from '../../utils/context/toastContext'
import { useNavigate } from 'react-router'

const recoverSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
})

type HandleRecover = {
  email: string
}

const PasswordRecovery = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { setToast } = useContext(ToastContext)

  const handleRecover = async ({ email }: HandleRecover) => {
    try {
      if (error) {
        setError('')
      }

      setLoading(true)
      await axios({
        method: 'POST',
        url: API.users.recover,
        data: {
          type: 'reset-password',
          to: email,
        },
      })

      setToast({
        status: 'SUCCESS',
        title: 'Password recovery sent',
        message: 'Check your email address to reset your password.',
      })

      navigate('/')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthTemplate title="Password Recovery">
      {error && <p className="text-error mb-3 text-center">{error}</p>}
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={recoverSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleRecover}
      >
        {({ errors, touched, handleSubmit, resetForm }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <Input
              label="Email"
              field={{
                name: 'email',
                type: 'email',
              }}
              error={errors.email && touched.email ? errors.email : undefined}
            />

            <div>
              <button type="submit" className="w-full button-primary" disabled={loading}>
                Recover Password
              </button>
            </div>
          </form>
        )}
      </Formik>
    </AuthTemplate>
  )
}

export default PasswordRecovery
