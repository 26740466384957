import { signOut } from '@firebase/auth'
import React from 'react'
import { auth } from '../../../../utils/api/firebase'

interface ContinueProps {
  onClick: () => void
  hidden?: boolean
}

const Continue = ({ onClick, hidden }: ContinueProps) => (
  <div className="w-full flex justify-end mt-12">
    <button onClick={() => signOut(auth)} className="text-error mr-12">
      Logout
    </button>
    {!hidden && (
      <button type="submit" className="w-40 button-primary" onClick={onClick}>
        Next
      </button>
    )}
  </div>
)

export default Continue
