/* This example requires Tailwind CSS v2.0+ */
import { Switch } from '@headlessui/react'
import { classNames } from '../../utils/functions'

interface ToggleProps {
  value: boolean
  setValue: (value: boolean) => void
  title: string
  description?: string
}

const Toggle = ({ value, setValue, description, title }: ToggleProps) => (
  <Switch.Group>
    <div className="flex items-center justify-between">
      <div className="flex-grow">
        <Switch.Label className="text-sm font-medium text-gray-900" passive>
          {title}
        </Switch.Label>
        {description && (
          <Switch.Description className="text-sm text-gray-500 mr-2">
            {description}
          </Switch.Description>
        )}
      </div>

      <Switch
        checked={value}
        onChange={setValue}
        className={classNames(
          value ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </div>
  </Switch.Group>
)

export default Toggle
