import React, { useContext } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useDocument } from 'react-firebase-hooks/firestore'

import { userRef } from '../../utils/api/firebase/functions/user'
import { auth } from '../../utils/api/firebase'
import { UserData } from '../../utils/types'
import userService from '../../utils/api/userService'
import ToastContext from '../../utils/context/toastContext'

import SettingsLayout from '../../components/layouts/SettingsLayout'
import ErrorBanner from '../../components/ErrorBanner'
import FormSpinner from '../../components/Settings/FormSpinner'
import MarketingPreferencesForm from '../../components/Settings/MarketingPreferencesForm'

const schema = Yup.object().shape({
  isSubscribedToSocialContent: Yup.boolean(),
})

type MarketingPreferencesDTO = {
  isSubscribedToSocialContent: boolean
}

const MarketingPreferences: React.FC = () => {
  const [user, loading, error] = useDocument(
    userRef(auth.currentUser?.uid || '')
  )
  const { setToast } = useContext(ToastContext)

  if (error) {
    return (
      <SettingsLayout title="Marketing Preferences">
        <ErrorBanner title="Something went wrong. Try to refresh!" />
      </SettingsLayout>
    )
  }

  if (loading) {
    return (
      <SettingsLayout title="Marketing Preferences">
        <FormSpinner />
      </SettingsLayout>
    )
  }

  const data = user?.data() as UserData

  const initialValues: MarketingPreferencesDTO = {
    isSubscribedToSocialContent: data.isSubscribedToSocialContent,
  }

  const handleOnSubmit = async (values: MarketingPreferencesDTO) => {
    try {
      await userService.updateUser(values, auth.currentUser?.uid || '')
      setToast({
        status: 'SUCCESS',
        title: 'Success',
        message: 'Your profile has been successfully updated!',
      })
    } catch (e) {
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: 'Something went wrong. Try again!',
      })
    }
  }

  return (
    <SettingsLayout title="Marketing Preferences">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<MarketingPreferencesDTO>) => (
          <Form>
            <MarketingPreferencesForm />
            <button
              type="submit"
              className="button-primary mt-6"
              disabled={isSubmitting || !dirty}
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </SettingsLayout>
  )
}

export default MarketingPreferences
