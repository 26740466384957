import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ToastContext from '../../utils/context/toastContext'
import userService from '../../utils/api/userService'

import SettingsLayout from '../../components/layouts/SettingsLayout'
import FormSpinner from '../../components/Settings/FormSpinner'
import Modal from '../../components/Modal'

const DeleteUser: React.FC = () => {
  const { setToast } = useContext(ToastContext)
  const [status, setStatus] = useState<'success' | 'loading' | 'stale'>('stale')
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleRequest = async () => {
    setStatus('loading')
    await userService.deleteUser()
    navigate('/')
    try {
    } catch {
      setStatus('stale')
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: 'Something went wrong. Try again!',
      })
    }
  }

  return (
    <SettingsLayout title="Request user data">
      <>
        <Modal
          open={open}
          closeModal={() => setOpen(false)}
          actionButtonFN={() => {
            setOpen(false)
            handleRequest()
          }}
          danger
          actionButtonText="Confirm"
          className="sm:max-w-md"
        >
          <h2 className="text-2xl mb-2 font-semibold">
            Are you sure you want to delete your account?
          </h2>
          <p>This is irreversible.</p>
        </Modal>

        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Delete account
          </h3>
          <p className="text-sm text-gray-500 leading-5 font-normal">
            Do you no longer want to participate and wish delete your account?
            We wil not store any of your personal information
          </p>
          {status === 'loading' ? (
            <FormSpinner />
          ) : (
            <button
              className="text-secondary-500 hover:text-secondary-300 my-2"
              onClick={() => setOpen(true)}
            >
              <span className="pr-3">Delete my account</span>→
            </button>
          )}
        </div>
      </>
    </SettingsLayout>
  )
}

export default DeleteUser
