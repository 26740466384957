type Action =
  | { type: 'SET_ERROR'; error: string }
  | { type: 'SET_LOADING'; loading: boolean }
  | { type: 'SET_PROGRESS'; progress: number }
  | { type: 'SET_UNITS'; unit: Units }
  | { type: 'SET_OPEN'; open: boolean }
  | { type: 'SET_TYPE'; activityType: string }

export interface MyProgressReducer {
  loading: boolean
  error: string
  progress: number
  unit: Units
  open: boolean
  activityType: string
}

export type Units = 'KM' | 'MI'

export const myProgressReducer = (state: MyProgressReducer, action: Action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.loading }
    case 'SET_ERROR':
      return { ...state, error: action.error }
    case 'SET_PROGRESS':
      return { ...state, progress: action.progress }
    case 'SET_UNITS':
      return { ...state, unit: action.unit }
    case 'SET_OPEN':
      return { ...state, open: action.open }
    case 'SET_TYPE':
      return { ...state, activityType: action.activityType }
    default:
      throw new Error('Wrong action type')
  }
}
