import React from 'react'
import { UserIcon, UsersIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const TypeItem: React.FC<{
  title: string
  text: string
  icon: any
  to: string
}> = (props) => (
  <div className="bg-primaryLight rounded-lg p-3 hover relative">
    <p className="text-white font-semibold">
      <Link to={props.to} className="hover:underline focus:outline-none">
        <span className="absolute inset-0" aria-hidden="true" />
        <span className="flex items-center">
          {props.icon} {props.title}
        </span>
      </Link>
    </p>
    <p className="text-white text-sm">{props.text}</p>
  </div>
)
const ChooseAccountType: React.FC = () => {
  return (
    <div>
      <h1 className="text-primary font-semibold text-2xl">
        Choose account type
      </h1>
      <p className="text-gray-600">
        Choose whether to log your activities as an individual or as a family.
      </p>

      <div className="my-4 space-y-3">
        <TypeItem
          icon={<UserIcon className="w-3 h-3 mr-2" />}
          title="Individual"
          to="individual"
          text="Log all your own activities and miles."
        />

        <TypeItem
          icon={<UsersIcon className="w-3 h-3 mr-2" />}
          title="Family account"
          to="family"
          text="With a family account, only you can log the group’s miles. Participants won’t have an account of their own. "
        />
      </div>
    </div>
  )
}

export default ChooseAccountType
