import React, { ReactElement } from 'react'
import { CheckCircleIcon } from '@heroicons/react/outline'

import { classNames } from '../utils/functions'

type SuccessMessageProps = {
  title?: string
  text?: ReactElement
  className?: string
}

const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
  return (
    <div
      className={classNames(
        'rounded-md bg-green-50 p-4',
        props.className ? props.className : ''
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {props.title ? (
            <h3 className="text-sm font-medium text-green-800">
              {props.title}
            </h3>
          ) : null}
          {props.text ? (
            <div className="mt-2 text-sm text-green-700">{props.text}</div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SuccessMessage
