import { XCircleIcon } from '@heroicons/react/solid'

interface ErrorBannerProps {
  title: string
  message?: string[]
}

const ErrorBanner = ({ title, message }: ErrorBannerProps) => (
  <div className="rounded-md bg-red-50 p-4 my-6">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{title}</h3>
        {message && (
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              {message.map((m) => (
                <li>{m}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default ErrorBanner
