import { User } from '@firebase/auth'
import { createContext } from 'react'

interface UserTokenContextProps {
  token: string
  user: User | null | undefined
  setToken: any
}

const UserTokenContext = createContext<UserTokenContextProps>({
  token: '',
  user: null,
  setToken: () => {},
})

export default UserTokenContext
