import React from 'react'
import { FieldProps, getIn } from 'formik'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import { classNames } from '../utils/functions'

const FormikInput: React.ComponentType<FieldProps & { label: string }> = ({
  field,
  form,
  label,
  ...rest
}) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div
        className={classNames(
          'appearance-none flex w-full border border-gray-300 rounded-md shadow-sm focus:outline-none mt-1 relative',
          hasError
            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
            : ''
        )}
      >
        <input
          id={field.name}
          className={classNames(
            'bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-50 disabled:text-gray-400',
            hasError ? 'text-red-900 placeholder-red-300' : ''
          )}
          {...field}
          {...rest}
        />
        {hasError ? (
          <div className="inset-y-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikInput
