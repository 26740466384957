type Action = { type: 'SET_ERROR'; error: string } | { type: 'SET_LOADING'; loading: boolean }

export interface BasicReducer {
  loading: boolean
  error: string
}

export const basicReducer = (state: BasicReducer, action: Action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.loading }
    case 'SET_ERROR':
      return { ...state, error: action.error }
    default:
      throw new Error('Wrong action type')
  }
}
