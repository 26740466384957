import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { classNames } from '../../utils/functions'
import { auth } from '../../utils/api/firebase'
import ToastContext from '../../utils/context/toastContext'

import DashboardTemplate from '../DashboardTemplate'
import UserTokenContext from '../../utils/context/UserTokenContext'

type SettingsLayoutProps = {
  children: React.ReactElement
  title: string
}

const SettingsLayout: React.FC<SettingsLayoutProps> = (props) => {
  const { setToast } = useContext(ToastContext)
  const { setToken } = useContext(UserTokenContext)

  const subNavigation = [
    { name: 'Profile Details', href: 'personal-details' },
    {
      name: 'Marketing Preferences',
      href: 'marketing-preferences',
    },
    { name: 'Password Reset', href: 'password-reset' },
    { name: 'Request user data', href: 'request-data' },
    { name: 'Delete an account', href: 'delete-account' },
  ]

  const handleLogOut = async () => {
    try {
      setToken('')
      await auth.signOut()
    } catch (e) {
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: 'Something went wrong. Try again!',
      })
    }
  }

  return (
    <DashboardTemplate title={props.title}>
      <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="py-6 lg:col-span-3">
          <div className="flex flex-col w-full h-full">
            <nav className="space-y-1 w-full mb-12">
              {subNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={`/settings/${item.href}`}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                      'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                    )
                  }
                >
                  <span className="truncate">{item.name}</span>
                </NavLink>
              ))}
              <a
                href="https://doddieaid.com/privacy"
                target="_blank"
                rel="noreferrer"
                className={
                  'group border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                }
              >
                <span className="truncate">Legal information</span>
              </a>
            </nav>
            <button
              className="mt-auto group border-l-4 px-3 py-2 flex items-center text-sm font-medium border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900"
              onClick={handleLogOut}
            >
              Log out
            </button>
          </div>
        </aside>

        <div className="divide-y divide-gray-200 lg:col-span-9">
          <div className="py-6 px-4 sm:p-6 lg:pb-8">{props.children}</div>
        </div>
      </div>
    </DashboardTemplate>
  )
}

export default SettingsLayout
