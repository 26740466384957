import THANKS from '../../assets/thank-you.png'
import { useDocument } from 'react-firebase-hooks/firestore'
import { userRef } from '../../utils/api/firebase/functions/user'
import { useContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { API } from '../../utils/api/endpoints'
import UserTokenContext from '../../utils/context/UserTokenContext'
import { CampaignStatus, ThankYouReducer } from '../../utils/reducers/thankYou'
import { SystemData, UserData } from '../../utils/types'
import ErrorBanner from '../../components/ErrorBanner'
import { systemConstantsRef } from '../../utils/api/firebase/functions/system'
import Waiting from './components/Waiting'
import Start from './components/Start'
import End from './components/End'
import Spinner from '../../components/Spinner'

interface ThankYouProps {
  uid?: string
}

const initialState = {
  loading: true,
  error: '',
  district: null,
  countdown: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  status: 'WAITING' as CampaignStatus,
}

/* const fakeStartDate = '12/10/2021 11:41:00'
const fakeEndDate = '12/10/2021 11:43:00' */

const ThankYou = ({ uid }: ThankYouProps) => {
  const [user, userLoading] = useDocument(userRef(uid || ''))
  const [system, systemLoading] = useDocument(systemConstantsRef)
  const { token } = useContext(UserTokenContext)
  const [{ loading, error, district, countdown, status }, dispatch] =
    useReducer(ThankYouReducer, initialState)

  const calculateTimeLeft = () => {
    const systemData = system?.data() as SystemData
    const startDate = systemData.campaign.startDate.toDate()
    const endDate = systemData.campaign.endDate.toDate()
    const differenceEnd = +new Date(endDate) - +new Date()
    const differenceStart = +new Date(startDate) - +new Date()

    if (differenceStart <= 0) {
      if (differenceEnd <= 0) {
        dispatch({ type: 'SET_CAMPAIGN_STATUS', status: 'END' })
      } else {
        dispatch({ type: 'SET_CAMPAIGN_STATUS', status: 'START' })
      }
    }

    return {
      days: Math.floor(differenceStart / (1000 * 60 * 60 * 24)),
      hours: Math.floor((differenceStart / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((differenceStart / 1000 / 60) % 60),
      seconds: Math.floor((differenceStart / 1000) % 60),
    }
  }

  useEffect(() => {
    const fetchDistrict = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: API.districts,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.data.length > 0) {
          // @ts-ignore doing the check on function call
          const data = user?.data() as UserData
          const findDistrict = response.data.find(
            ({ id }: { id: string }) => id === data.district.id
          )

          dispatch({ type: 'SET_DISTRICT', district: findDistrict })
        }
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    }

    const fetchFirstCountdown = async () => {
      try {
        dispatch({ type: 'SET_COUNTDOWN', countdown: calculateTimeLeft() })
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    }

    const runInitialFunctions = async () => {
      if (!userLoading && !systemLoading) {
        await fetchDistrict()
        await fetchFirstCountdown()
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }

    runInitialFunctions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading, systemLoading])

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: 'SET_COUNTDOWN', countdown: calculateTimeLeft() })
    }, 1000)

    status === 'START' && clearTimeout(timer)

    return () => clearTimeout(timer)
  })

  if (userLoading || loading || systemLoading) {
    return <Spinner />
  }

  const systemData = system?.data() as SystemData

  return (
    <div className="text-white flex flex-col items-center my-12 text-center gap-6">
      {error && <ErrorBanner title={error} />}
      <img src={THANKS} alt="thank you" />

      {status === 'WAITING' && (
        <Waiting countdown={countdown} district={district} />
      )}
      {status === 'START' && <Start district={district} />}
      {status === 'END' && (
        <End endOfCampaignMessage={systemData.endOfCampaignMessage} />
      )}
    </div>
  )
}

export default ThankYou
