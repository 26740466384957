import axios from 'axios'

import { UserData } from '../types'
import { API } from './endpoints'
import { auth } from './firebase'

class UserService {
  updateUser = async (
    data: Partial<UserData>,
    userId: string
  ): Promise<UserData> => {
    const token = await auth.currentUser?.getIdToken(true)

    const response = await axios.patch<UserData>(
      `${API.users.update}/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  requestData = async () => {
    const token = await auth.currentUser?.getIdToken(true)

    const response = await axios.post(
      `${API.users.dataRequest}/${auth.currentUser?.uid}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  deleteUser = async (): Promise<UserData> => {
    const token = await auth.currentUser?.getIdToken(true)

    const response = await axios.delete<UserData>(
      `${API.users.delete}/${auth.currentUser?.uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }
}

const userService = new UserService()

export default userService
