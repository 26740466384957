import { useContext, useEffect, useReducer } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { basicReducer } from '../../utils/reducers/basic'
import { signInWithEmailAndPassword } from '@firebase/auth'
import { auth } from '../../utils/api/firebase'
import Input from '../../components/Input'
import { Link, useNavigate } from 'react-router-dom'
import ToastContext from '../../utils/context/toastContext'
import AuthTemplate from '../../components/AuthTemplate'
import { useAuthState } from 'react-firebase-hooks/auth'
import End from '../ThankYou/components/End'
import { SystemData } from '../../utils/types'
import { useDocument } from 'react-firebase-hooks/firestore'
import { systemConstantsRef } from '../../utils/api/firebase/functions/system'
import Spinner from '../../components/Spinner'

type HandleLogin = {
  email: string
  password: string
}

const initialState = {
  loading: false,
  error: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(6, 'Password is too short - should be 6 chars minimum.'),
})

const Login = () => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [{ error, loading }, dispatch] = useReducer(basicReducer, initialState)
  const { setToast } = useContext(ToastContext)

  const [system, systemLoading] = useDocument(systemConstantsRef)

  const systemData = system?.data() as SystemData | undefined
  const endDate = systemData?.campaign?.endDate.toDate()
  const differenceEnd = +new Date(endDate || '') - +new Date()

  useEffect(() => {
    if (user) {
      navigate('dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleLogIn = async ({ email, password }: HandleLogin) => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true })

      await signInWithEmailAndPassword(auth, email, password)
      setToast({
        status: 'SUCCESS',
        title: 'SUCCESS',
        message: 'Logged in',
      })
    } catch (e) {
      dispatch({
        type: 'SET_ERROR',
        error: 'Your email and password do not match. Please try again.',
      })
    } finally {
      dispatch({ type: 'SET_LOADING', loading: false })
    }
  }

  if (systemLoading) {
    return <Spinner />
  }

  if (differenceEnd && differenceEnd <= 0) {
    return (
      <div className="text-white flex flex-col items-center my-12 text-center gap-6">
        <End
          endOfCampaignMessage={
            systemData ? systemData.endOfCampaignMessage : ''
          }
        />
      </div>
    )
  }
  return (
    <AuthTemplate title="Log in or register">
      {error && <p className="text-error mb-3 text-center">{error}</p>}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={loginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleLogIn}
      >
        {({ errors, touched, handleSubmit, resetForm }) => (
          <>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <Input
                label="Email"
                field={{
                  name: 'email',
                  type: 'email',
                }}
                error={errors.email && touched.email ? errors.email : undefined}
              />

              <Input
                label="Password"
                field={{
                  name: 'password',
                  type: 'password',
                  autoComplete: 'current-password',
                }}
                error={
                  errors.password && touched.password
                    ? errors.password
                    : undefined
                }
              />

              <div className="text-sm">
                <Link
                  to="recover-password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </Link>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full button-primary"
                  disabled={loading}
                >
                  Sign in
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>
            </div>

            <Link to="/register" className="mt-6 w-full button-secondary">
              Register
            </Link>
          </>
        )}
      </Formik>
    </AuthTemplate>
  )
}

export default Login
