import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'

import AuthTemplate from '../../components/AuthTemplate'
import ChooseAccountType from '../../components/ChooseAccountType'

import { auth } from '../../utils/api/firebase'

const Register = () => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <AuthTemplate title="Register your account">
      <ChooseAccountType />
    </AuthTemplate>
  )
}

export default Register
