import axios from 'axios'
import { useContext, useEffect, useReducer } from 'react'
import { PuffLoader } from 'react-spinners'
import ErrorBanner from '../../components/ErrorBanner'

import { API } from '../../utils/api/endpoints'
import getStripe from '../../utils/api/stripe/getStripe'
import UserTokenContext from '../../utils/context/UserTokenContext'
import { stepsReducer, Taxpayer } from '../../utils/reducers/steps'
import { UserData } from '../../utils/types'
import Continue from './components/Continue'
import DistrictStep from './components/DistrictStep'
import DonateStep from './components/DonateStep'
import Preview from './components/Preview'
import StepBar from './components/StepBar'
import TaxpayerStep from './components/TaxpayerStep'
import AddressDetails from './components/AddressDetails'

interface StepsProps {
  startingPoint: number
  userData: UserData
}

const Steps = ({ startingPoint, userData }: StepsProps) => {
  const initialState = {
    loading: true,
    error: '',
    districts: [],
    step: startingPoint,
    giftAid: Boolean(userData?.taxpayerAddress?.postcode),
    userData: undefined,
    taxpayer: {
      title: '',
      firstName: userData.firstName,
      lastName: userData.lastName,
      taxpayer: false,
      taxpayerAddress: {
        country: '',
        city: '',
        postcode: '',
        streetAddress: '',
      },
    },
  }
  const [{ districts, step, error, loading, giftAid, taxpayer }, dispatch] =
    useReducer(stepsReducer, initialState)

  const { token, user } = useContext(UserTokenContext)

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: API.districts,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.data.length > 0) {
          dispatch({ type: 'SET_DISTRICTS', districts: response.data })
        }
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    }

    const handleStarter = async () => {
      await fetchDistricts()

      dispatch({ type: 'SET_LOADING', loading: false })
    }

    handleStarter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectDistrict = async (id: string) => {
    if (user) {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })

        await axios({
          method: 'PATCH',
          url: `${API.users.create}/${user.uid}`,
          data: {
            districtId: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        dispatch({ type: 'SET_STEP', step: step + 1 })
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }
  }

  const handleTaxpayer = async ({
    title,
    firstName,
    lastName,
    postcode,
    houseNumber,
    streetName,
    townOrCity,
    country,
  }: {
    title: string
    firstName: string
    lastName: string
    postcode: string
    houseNumber: string
    streetName: string
    townOrCity: string
    country: string
  }) => {
    if (user) {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })

        const updatedData: Omit<Taxpayer, 'taxpayer'> = {
          title,
          firstName,
          lastName,
          taxpayerAddress: {
            country,
            city: townOrCity,
            postcode: postcode,
            streetAddress: `${houseNumber}, ${streetName}`,
          },
        }

        await axios({
          method: 'PATCH',
          url: `${API.users.update}/${user.uid}`,
          data: {
            taxpayerAddress: {
              ...updatedData.taxpayerAddress,
              title: updatedData.title,
              firstName: updatedData.firstName,
              lastName: updatedData.lastName,
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        dispatch({ type: 'SET_STEP', step: step + 1 })
        dispatch({
          type: 'SET_TAXPAYER',
          taxpayer: { ...updatedData, taxpayer: true },
        })
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }
  }

  const handleShippingAddress = async ({
    title,
    firstName,
    lastName,
    postcode,
    streetName,
    townOrCity,
    country,
  }: {
    title: string
    firstName: string
    lastName: string
    postcode: string
    streetName: string
    townOrCity: string
    country: string
  }) => {
    if (user) {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })

        await axios({
          method: 'PATCH',
          url: `${API.users.update}/${user.uid}`,
          data: {
            address: {
              title,
              firstName,
              lastName,
              country,
              city: townOrCity,
              postcode: postcode,
              streetAddress: streetName,
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        dispatch({ type: 'SET_STEP', step: step + 1 })
      } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e.message })
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }
  }

  const handleGiftAid = (toggle: boolean) => {
    dispatch({ type: 'SET_GIFT_AID', giftAid: toggle })
  }

  const handlePay = async () => {
    try {
      const stripe = await getStripe()

      if (!stripe) {
        throw new Error('Stripe is not loaded')
      }

      const response = await axios({
        method: 'POST',
        url: `${API.stripe}`,
        data: {
          type: 'REGISTRATION',
          isGiftAidDonation: giftAid,
          amount: 2000,
          isUsingStripeCheckout: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    }
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-5xl">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <StepBar step={step} giftAid={giftAid} />

            {error && <ErrorBanner title={error} />}

            {loading && (
              <div className="flex w-full justify-center my-24">
                <PuffLoader color="#232F61" />
              </div>
            )}

            {!loading && step === 1 && districts.length > 0 && (
              <DistrictStep
                handleSelectDistrict={handleSelectDistrict}
                districts={districts}
              />
            )}

            {!loading && step === 2 && (
              <>
                <DonateStep handleGiftAid={handleGiftAid} giftAid={giftAid} />
                <Continue
                  onClick={() => dispatch({ type: 'SET_STEP', step: step + 1 })}
                />
              </>
            )}

            {!loading && step === 3 && giftAid && (
              <TaxpayerStep
                handleTaxpayer={handleTaxpayer}
                taxpayer={taxpayer}
              />
            )}

            {!loading &&
              ((step === 4 && giftAid) || (step === 3 && !giftAid)) && (
                <AddressDetails
                  onSubmit={handleShippingAddress}
                  taxpayer={taxpayer}
                  enableCopy={giftAid}
                />
              )}

            {!loading &&
              userData &&
              (step === 5 || (step === 4 && !giftAid)) && (
                <Preview
                  giftAid={giftAid}
                  userData={userData}
                  handlePay={handlePay}
                />
              )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Steps
