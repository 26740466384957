import AuthTemplate from '../components/AuthTemplate'

export default function GiftingThankYou() {
  return (
    <AuthTemplate title="Xmas Gifting">
      <h1 className="text-xl font-semibold mt-4">Thank you!</h1>
      <p className="mb-4 text-sm text-gray-500">
        Your friend will receive a Free Entry for Doddie Aid 2024.
      </p>
    </AuthTemplate>
  )
}
