export const API = {
  users: {
    recover: `${process.env.REACT_APP_API_URL}/emails`,
    create: `${process.env.REACT_APP_API_URL}/users`,
    update: `${process.env.REACT_APP_API_URL}/users`,
    delete: `${process.env.REACT_APP_API_URL}/users`,
    dataRequest: `${process.env.REACT_APP_API_URL}/data-requests/users`,
  },
  districts: `${process.env.REACT_APP_API_URL}/districts`,
  stripe: `${process.env.REACT_APP_API_URL}/donations`,
  system: {
    timestamp: `${process.env.REACT_APP_API_URL}/system/timestamp`,
  },
  activities: `${process.env.REACT_APP_API_URL}/activities`,
  gifting: `${process.env.REACT_APP_API_URL}/donations/gift-entry`,
}
