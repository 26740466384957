import MAIL from '../../../assets/icons/mail2.svg'

const Waiting = ({ countdown, district }: any) => (
  <>
    <img src={MAIL} alt="mail" />

    <p className="max-w-md">
      Check your email for confirmation of your donation and instructions on how
      to claim your free snood.
    </p>

    <>
      <p className="text-lg font-semibold">The challenge kicks off in...</p>
      <div className="flex gap-6 py-3 px-6 rounded-full bg-gradient-to-b from-primary to-primaryLight sm:text-2xl font-semibold">
        <div>
          <p>{countdown.days}</p>
          <p className="font-normal">Days</p>
        </div>
        <span className="border-r border-gray-white" />
        <div>
          <p>{countdown.hours}</p>
          <p className="font-normal">Hours</p>
        </div>
        <span className="border-r border-gray-white" />
        <div>
          <p>{countdown.minutes}</p>
          <p className="font-normal">Minutes</p>
        </div>
        <span className="border-r border-gray-white" />
        <div>
          <p>{countdown.seconds}</p>
          <p className="font-normal">Seconds</p>
        </div>
      </div>
    </>

    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col items-center text-center text-black">
      {district?.captain?.avatar?.url ? (
        <img
          src={district.captain.avatar.url}
          alt="thank you face"
          className="rounded-full w-16 h-16 object-cover mb-3 font-semibold"
        />
      ) : null}

      <p>Welcome to the {district?.name} district</p>
    </div>
  </>
)

export default Waiting
