import React from 'react'
import { Field } from 'formik'

import FormikSwitch from '../FormikSwitch'

const MarketingPreferencesForm: React.FC = () => {
  return (
    <div>
      <Field
        name="isSubscribedToSocialContent"
        component={FormikSwitch}
        label="Marketing Preferences"
        description="I'd like to occasionally hear from My Name'5 Doddie Foundation."
      />
    </div>
  )
}

export default MarketingPreferencesForm
