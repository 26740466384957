import { Field } from 'formik'
import React from 'react'

import FormikInput from '../FormikInput'

const PersonalDetailsForm: React.FC = () => {
  return (
    <div className="mt-6 grid grid-cols-12 gap-6">
      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="title"
          component={FormikInput}
          label="Title*"
          placeholder="Title"
        />
      </div>

      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="firstName"
          component={FormikInput}
          label="First Name*"
          placeholder="First Name"
        />
      </div>
      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="lastName"
          component={FormikInput}
          label="Last Name*"
          placeholder="Last Name"
        />
      </div>

      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="streetName"
          component={FormikInput}
          label="Street name*"
          placeholder="Street name"
        />
      </div>
      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="townOrCity"
          component={FormikInput}
          label="Town or city*"
          placeholder="Town or city"
        />
      </div>
      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="country"
          component={FormikInput}
          label="Country*"
          placeholder="Country"
        />
      </div>

      <div className="col-span-12 md:col-span-4">
        <Field
          type="text"
          name="postcode"
          component={FormikInput}
          label="Postcode*"
          placeholder="Postcode"
        />
      </div>
    </div>
  )
}

export default PersonalDetailsForm
