import React, { useContext, useState } from 'react'

import ToastContext from '../../utils/context/toastContext'
import userService from '../../utils/api/userService'

import SettingsLayout from '../../components/layouts/SettingsLayout'
import FormSpinner from '../../components/Settings/FormSpinner'
import SuccessMessage from '../../components/SuccessMessage'

const RequestUserData: React.FC = () => {
  const { setToast } = useContext(ToastContext)
  const [status, setStatus] = useState<'success' | 'loading' | 'stale'>('stale')

  const handleRequest = async () => {
    setStatus('loading')
    await userService.requestData()
    setStatus('success')
    try {
    } catch {
      setStatus('stale')
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: 'Something went wrong. Try again!',
      })
    }
  }

  return (
    <SettingsLayout title="Request user data">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Request a copy of your data
        </h3>
        <p className="text-sm text-gray-500 leading-5 font-normal">
          Per GDPR, you are able to request a copy of your data. This request
          will be processed in the background, and you will receive a copy of
          your data via email once it is ready.
        </p>
        {status === 'success' ? (
          <SuccessMessage
            title="Requested data sent!"
            text={<p>Check your email to download your data.</p>}
            className="my-2"
          />
        ) : status === 'loading' ? (
          <FormSpinner />
        ) : (
          <button
            className="text-secondary-500 hover:text-secondary-300 my-2"
            onClick={handleRequest}
          >
            <span className="pr-3">Request a copy of your data</span>→
          </button>
        )}
      </div>
    </SettingsLayout>
  )
}

export default RequestUserData
