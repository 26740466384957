import ENDOFCAMPAIGN from '../../../assets/endofcampaign.png'

const End = ({ endOfCampaignMessage }: { endOfCampaignMessage: string }) => (
  <>
    <p className="max-w-md my-6 whitespace-pre-wrap">
      {endOfCampaignMessage.replaceAll('   ', '\n\n')}
    </p>
    <img src={ENDOFCAMPAIGN} alt="end of campaign" />
  </>
)

export default End
