import { createContext } from 'react'
import { ToastProps } from '../../components/Toast'

export const toastInitialState: ToastProps = {
  status: 'SUCCESS',
  message: '',
  title: '',
}

type ToastContextProps = {
  toast: ToastProps
  setToast: React.Dispatch<React.SetStateAction<ToastProps>>
}

const ToastContext = createContext<ToastContextProps>({
  toast: toastInitialState,
  setToast: () => null,
})

export default ToastContext
