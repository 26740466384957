import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuthState } from 'react-firebase-hooks/auth'
import Login from './Login'
import { auth } from '../utils/api/firebase'
import Dashboard from './Dashboard'
import { useEffect, useState } from 'react'
import Toast, { ToastProps } from '../components/Toast'
import ToastContext, { toastInitialState } from '../utils/context/toastContext'
import Portal from '../components/Portal'
import PasswordRecovery from './PasswordRecovery'
import RequireAuth from '../components/RequireAuth'
import Register from './Register'
import UserTokenContext from '../utils/context/UserTokenContext'
import ThankYou from './ThankYou'
import PersonalDetails from './Settings/PersonalDetails'
import Spinner from '../components/Spinner'
import ResetPassword from './Settings/ResetPassword'
import MarketingPreferences from './Settings/MarketingPreferences'
import RequestUserData from './Settings/RequestUserData'
import RegisterFamily from './RegisterFamily'
import RegisterIndividual from './RegisterIndividual'
import DeleteUser from './Settings/DeleteUser'
import { Gifting } from './Gifting'
import GiftingThankYou from './GiftingThankYou'

function App() {
  const [user, loading] = useAuthState(auth)
  const [token, setToken] = useState('')
  const [toast, setToast] = useState<ToastProps>(toastInitialState)

  useEffect(() => {
    const handleUser = async () => {
      try {
        if (user) {
          const result = await user.getIdTokenResult()
          // Check if the custom claims exist

          while (!result.claims.role) {
            // Loop till role is set
            const check = await user.getIdTokenResult(true)
            if (check.claims.role) {
              // Assign the token to the context and stop loading
              const token = await user.getIdToken()
              setToken(token)
              break
            }
          }

          if (result.claims.role) {
            // If the page gets refreshed the loop will not run because it meets the criteria
            // Instead this will do the: Assign the token to the context and stop loading
            const token = await user.getIdToken()
            setToken(token)
          }
        }
      } catch (error) {
        console.log('🚀 ~ file: App.tsx ~ line 29 ~ handleUser ~ error', error)
      }
    }

    if (user) {
      handleUser()
    }
  }, [user])

  if (loading || (!loading && user && !token)) {
    return <Spinner />
  }

  return (
    <Router>
      <ToastContext.Provider value={{ toast, setToast }}>
        <UserTokenContext.Provider value={{ token, user, setToken }}>
          <Portal>
            <Toast
              status={toast.status}
              message={toast.message}
              title={toast.title}
            />
          </Portal>

          <Helmet>
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/b141d87d-20ff-410c-9028-1d20ecfcec2f/cd.js"
              type="text/javascript"
              async
            ></script>
          </Helmet>

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/register/individual"
              element={<RegisterIndividual />}
            />
            <Route path="/register/family" element={<RegisterFamily />} />
            <Route path="recover-password" element={<PasswordRecovery />} />
            <Route path="/gifting" element={<Gifting />} />
            <Route path="/gifting-thank-you" element={<GiftingThankYou />} />
            <Route element={<RequireAuth />}>
              <Route
                path="/dashboard"
                element={<Dashboard uid={user?.uid} />}
              />
              <Route path="/thank-you" element={<ThankYou uid={user?.uid} />} />
              <Route
                path="/settings"
                element={<Navigate replace to="/settings/personal-details" />}
              />
              <Route
                path="/settings/personal-details"
                element={<PersonalDetails />}
              />
              <Route
                path="/settings/password-reset"
                element={<ResetPassword />}
              />
              <Route
                path="/settings/marketing-preferences"
                element={<MarketingPreferences />}
              />
              <Route
                path="/settings/request-data"
                element={<RequestUserData />}
              />
              <Route path="/settings/delete-account" element={<DeleteUser />} />
            </Route>
          </Routes>
        </UserTokenContext.Provider>
      </ToastContext.Provider>
    </Router>
  )
}

export default App
