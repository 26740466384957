import { useContext, useEffect, useReducer } from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import { createUserWithEmailAndPassword } from '@firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'

import Input from '../../components/Input'
import AuthTemplate from '../../components/AuthTemplate'
import Toggle from '../../components/Toggle'

import ToastContext from '../../utils/context/toastContext'
import { basicReducer } from '../../utils/reducers/basic'
import { auth } from '../../utils/api/firebase'
import { API } from '../../utils/api/endpoints'

type HandleLogin = {
  email: string
  emailConfirm: string
  password: string
  newsletter: boolean
  groupName: string
}

const initialState = {
  loading: false,
  error: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match!')
    .required()
    .label('Email'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .label('Password'),
  groupName: Yup.string().required(),
  newsletter: Yup.boolean().required().label('Newsletter'),
})

const RegisterFamily = () => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [{ error, loading }, dispatch] = useReducer(basicReducer, initialState)
  const { setToast } = useContext(ToastContext)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleAuth = async ({
    email,
    password,
    newsletter,
    groupName,
  }: HandleLogin) => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true })

      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )

      const token = await response.user.getIdToken()

      await axios({
        method: 'POST',
        url: API.users.create,
        data: {
          groupName,
          isGroupAccount: true,
          isSubscribedToSocialContent: newsletter,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setToast({
        status: 'SUCCESS',
        title: 'SUCCESS',
        message: 'Registered',
      })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    } finally {
      dispatch({ type: 'SET_LOADING', loading: false })
    }
  }

  return (
    <AuthTemplate title="Register your account">
      {error && <p className="text-error mb-3 text-center">{error}</p>}
      <Formik
        initialValues={{
          email: '',
          emailConfirm: '',
          password: '',
          newsletter: false,
          groupName: '',
        }}
        validationSchema={loginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleAuth}
      >
        {({ errors, touched }) => (
          <>
            <Form className="space-y-6">
              <Input
                label="Family name (eg.: The Bexters)"
                field={{
                  name: 'groupName',
                  type: 'text',
                }}
                error={
                  errors.groupName && touched.groupName
                    ? errors.groupName
                    : undefined
                }
              />

              <Input
                label="Email"
                field={{
                  name: 'email',
                  type: 'email',
                }}
                error={errors.email && touched.email ? errors.email : undefined}
              />

              <Input
                label="Confirm email address"
                field={{
                  name: 'emailConfirm',
                  type: 'email',
                }}
                error={errors.emailConfirm ? errors.emailConfirm : undefined}
                preventActions={{
                  paste: true,
                }}
              />

              <Input
                label="Password"
                field={{
                  name: 'password',
                  type: 'password',
                  autoComplete: 'current-password',
                }}
                error={errors.email && touched.email ? errors.email : undefined}
                description="should be 8 chars minimum."
              />

              <Field name="newsletter">
                {({ field, form }: FieldProps) => (
                  <Toggle
                    title="Newsletter"
                    description="I’d like to occasionally hear from My Name’5 Doddie Foundation."
                    value={field.value}
                    setValue={(val) => form.setFieldValue('newsletter', val)}
                  />
                )}
              </Field>

              <div>
                <button
                  type="submit"
                  className="w-full button-primary"
                  disabled={loading}
                >
                  Register
                </button>
              </div>
            </Form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>
            </div>

            <Link to="/" className="mt-6 w-full button-secondary">
              Sign in
            </Link>
          </>
        )}
      </Formik>
    </AuthTemplate>
  )
}

export default RegisterFamily
