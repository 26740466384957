import { InformationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import Modal from '../../../../components/Modal'
import Toggle from '../../../../components/Toggle'
import SNOOD from '../../../../assets/snood.png'
import SnoodItem from '../../../../assets/snood-item.png'

interface DonateStepProps {
  handleGiftAid: (toggle: boolean) => void
  giftAid: boolean
}

const DonateStep = ({ handleGiftAid, giftAid }: DonateStepProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        actionButtonFN={() => {
          setOpen(false)
        }}
        actionButtonText="I understand"
        className="sm:max-w-sm"
      >
        <div className="flex w-full justify-center mb-6">
          <img src={SNOOD} alt="snood" className="rounded-lg" />
        </div>
        <h2 className="text-2xl mb-2 font-semibold">
          What’s a district snood?
        </h2>
        <p className="mb-3">Each district has its own distinct tartan.</p>
        <p className="mb-3">
          An iconic piece of kit from Doddie events past, the snood is a
          versatile accessory ideal for a range of activities.
        </p>
        <p className="mb-3">
          It can be worn as a neck warmer, headband, wristband, hairband,
          balaclava, scarf, scrunchie, beanie and even a bandanna.
        </p>
      </Modal>

      <h1 className="text-2xl mb-2 font-semibold">Donate</h1>
      <p className="mb-12 max-w-md">
        We ask participants for a £20 donation to the My Name’5 Doddie
        Foundation. You’ll receive a free district snood as a thank you for
        taking part.
        <button
          onClick={() => setOpen(true)}
          className="ml-1 w-5 text-yellow-500 cursor-pointer"
        >
          <InformationCircleIcon />
        </button>
      </p>

      <div className="max-w-sm">
        <img
          src={SnoodItem}
          alt="Snood"
          className="mx-auto mb-4"
          style={{ maxWidth: 250 }}
        />

        <div className="mb-6 rounded-lg bg-primary text-white flex justify-between content-center p-3">
          <p>Donation amount</p>
          <p>£20.00</p>
        </div>
      </div>

      <div
        style={{
          maxWidth: '24rem',
        }}
      >
        <Toggle
          title="I would like to add Gift Aid"
          description="If you pay UK tax we can claim back 25% from the Government. That’s 25p for every pound!"
          value={giftAid}
          setValue={(val) => handleGiftAid(val)}
        />
      </div>
    </div>
  )
}

export default DonateStep
