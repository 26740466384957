import { Link } from 'react-router-dom'
import MAIL from '../../../assets/icons/mail2.svg'

const Start = ({ district }: any) => (
  <>
    <img src={MAIL} alt="mail" />

    <p className="max-w-md">
      Check your email for confirmation of your donation and instructions on how
      to claim your free snood.
    </p>

    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col items-center text-center text-black">
      <img
        src={district?.captain.avatar.url}
        alt="thank you face"
        className="rounded-full w-16 h-16 object-cover mb-3 font-semibold"
      />
      <p>Welcome to the {district?.name} district</p>

      <Link to="/dashboard" className="button-primary bg-primaryLight mt-6">
        Get started
      </Link>
    </div>
  </>
)

export default Start
