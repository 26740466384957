import { signOut } from '@firebase/auth'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../../../../components/FormikInput'
import Toggle from '../../../../components/Toggle'
import { auth } from '../../../../utils/api/firebase'
import { Taxpayer } from '../../../../utils/reducers/steps'

const taxpayerSchema = Yup.object().shape({
  title: Yup.string().required('Title is a required field.'),
  firstName: Yup.string().required('First Name is a required field.'),
  lastName: Yup.string().required('Last Name is a required field.'),
  postcode: Yup.string().required('Postcode is a required field.'),
  houseNumber: Yup.string().required('House Number is a required field.'),
  streetName: Yup.string().required('Street Name is a required field.'),
  townOrCity: Yup.string().required('Town is a required field.'),
  country: Yup.string().required('Country is a required field.'),
  taxpayer: Yup.boolean().required(),
})

interface TaxpayerStepProps {
  handleTaxpayer: (we: any) => Promise<void>
  taxpayer: Taxpayer
}

const TaxpayerStep = ({ handleTaxpayer, taxpayer }: TaxpayerStepProps) => (
  <Formik
    initialValues={{
      title: '',
      firstName: taxpayer.firstName,
      lastName: taxpayer.lastName,
      postcode: '',
      houseNumber: '',
      streetName: '',
      townOrCity: '',
      country: '',
      taxpayer: false,
    }}
    validationSchema={taxpayerSchema}
    validateOnBlur={false}
    validateOnChange={false}
    onSubmit={handleTaxpayer}
  >
    {({ values }) => (
      <Form className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <Field
            type="text"
            name="title"
            component={FormikInput}
            label="Title"
            placeholder="Title"
            autoFocus
          />

          <Field
            type="text"
            name="firstName"
            component={FormikInput}
            label="First Name"
            placeholder="First Name"
          />

          <Field
            type="text"
            name="lastName"
            component={FormikInput}
            label="Last Name"
            placeholder="Last Name"
          />

          <Field
            type="text"
            name="houseNumber"
            component={FormikInput}
            label="House name or number"
            placeholder="House name or number"
          />

          <Field
            type="text"
            name="streetName"
            component={FormikInput}
            label="Street name"
            placeholder="Street name"
          />

          <Field
            type="text"
            name="townOrCity"
            component={FormikInput}
            label="Town or city"
            placeholder="Town or city"
          />

          <Field
            type="text"
            name="country"
            component={FormikInput}
            label="Country"
            placeholder="Country"
          />

          <Field
            type="text"
            name="postcode"
            component={FormikInput}
            label="Postcode"
            placeholder="Postcode"
          />
        </div>

        <div className="max-w-sm">
          <Field name="taxpayer">
            {({ field, form }: FieldProps) => (
              <Toggle
                title="I am a UK taxpayer (required)"
                description="Please treat all donations I make or have made to the
                       My Name’5 Doddie Foundation as Gift Aid Donations until further notice."
                value={field.value}
                setValue={(val) => form.setFieldValue('taxpayer', val)}
              />
            )}
          </Field>
        </div>

        <div className="w-full flex justify-end mt-12">
          <button
            type="button"
            onClick={() => signOut(auth)}
            className="text-error mr-12"
          >
            Logout
          </button>

          <button
            type="submit"
            className="w-40 button-primary"
            disabled={!values.taxpayer}
          >
            Next
          </button>
        </div>
      </Form>
    )}
  </Formik>
)

export default TaxpayerStep
