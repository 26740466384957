import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { Field, FieldProps, Formik, FormikErrors, FormikTouched } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import AuthTemplate from '../components/AuthTemplate'
import Input from '../components/Input'
import Toggle from '../components/Toggle'
import { API } from '../utils/api/endpoints'
import UserTokenContext from '../utils/context/UserTokenContext'
import { GiftingDto } from '../utils/types'

const giftingSchema = Yup.object().shape({
  title: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  firstName: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  lastName: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  streetAddress: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  postcode: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  city: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  country: Yup.string().when('isGiftAidDonation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  isGiftAidDonation: Yup.boolean(),
  emailMessage: Yup.string().required('Required'),
  receiverEmail: Yup.string().email().required('Required'),
  purchaserEmail: Yup.string().email().required('Required'),
})

export function Gifting() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { token } = useContext(UserTokenContext)

  const initialValues: GiftingDto = {
    title: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    postcode: '',
    city: '',
    country: '',
    isGiftAidDonation: false,
    emailMessage: '',
    receiverEmail: '',
    purchaserEmail: '',
  }

  const hasError = (
    errors: FormikErrors<GiftingDto>,
    touched: FormikTouched<GiftingDto>,
    fieldName: keyof GiftingDto
  ) => {
    return errors[fieldName] && touched[fieldName]
      ? errors[fieldName]
      : undefined
  }

  const handleSubmit = async (values: GiftingDto) => {
    try {
      if (error) {
        setError('')
      }

      setLoading(true)
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API || '')

      if (!stripe) {
        throw new Error('Stripe is not loaded')
      }

      const response = await axios({
        method: 'POST',
        url: API.gifting,
        data: {
          isUsingStripeCheckout: true,
          taxpayerAddress: {
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            streetAddress: values.streetAddress,
            postcode: values.postcode,
            city: values.city,
            country: values.country,
          },
          isGiftAidDonation: values.isGiftAidDonation,
          emailMessage: values.emailMessage,
          receiverEmail: values.receiverEmail,
          purchaserEmail: values.purchaserEmail,
        },
        ...(token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : null),
      })

      if (response.data && response.data.sessionId) {
        await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        })
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthTemplate title="Xmas Gifting">
      {error && <p className="text-error mb-3 text-center">{error}</p>}
      <Formik
        initialValues={initialValues}
        validationSchema={giftingSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleSubmit, values }) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <Input
              label="Email Address"
              field={{
                name: 'purchaserEmail',
                type: 'email',
              }}
              error={hasError(errors, touched, 'purchaserEmail')}
            />

            <Input
              label="Receiver Email Address"
              field={{
                name: 'receiverEmail',
                type: 'email',
              }}
              error={hasError(errors, touched, 'receiverEmail')}
            />

            <Input
              label="Message"
              field={{
                name: 'emailMessage',
                type: 'text',
              }}
              error={hasError(errors, touched, 'emailMessage')}
            />
            <Field name="isGiftAidDonation">
              {({ field, form }: FieldProps) => (
                <Toggle
                  title="I would like to add Gift Aid"
                  description=" If you pay UK tax we can claim back 25% from the Government. That’s 25p for every pound!"
                  value={field.value}
                  setValue={(val) =>
                    form.setFieldValue('isGiftAidDonation', val)
                  }
                />
              )}
            </Field>

            <div
              className={
                values.isGiftAidDonation ? 'block space-y-6' : 'hidden'
              }
            >
              <Input
                label="Title"
                field={{
                  name: 'title',
                  type: 'text',
                }}
                error={hasError(errors, touched, 'title')}
              />
              <div className="md:flex md:space-x-4 space-y-6 md:space-y-0">
                <Input
                  label="First Name"
                  field={{
                    name: 'firstName',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'firstName')}
                />
                <Input
                  label="Last Name"
                  field={{
                    name: 'lastName',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'lastName')}
                />
              </div>
              <div className="md:flex md:space-x-4 space-y-6 md:space-y-0">
                <Input
                  label="Street Name"
                  field={{
                    name: 'streetAddress',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'streetAddress')}
                />
                <Input
                  label="Town or City"
                  field={{
                    name: 'city',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'city')}
                />
              </div>
              <div className="md:flex md:space-x-4 space-y-6 md:space-y-0">
                <Input
                  label="Country"
                  field={{
                    name: 'country',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'country')}
                />
                <Input
                  label="Postcode"
                  field={{
                    name: 'postcode',
                    type: 'text',
                  }}
                  error={hasError(errors, touched, 'postcode')}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full button-primary"
                disabled={loading}
              >
                Pay £20.00
              </button>
            </div>
          </form>
        )}
      </Formik>
    </AuthTemplate>
  )
}
