import { District } from '../../../../utils/types'
import PEOPLE from '../../../../assets/icons/people.svg'
import STONKS from '../../../../assets/icons/stonks.svg'
import Modal from '../../../../components/Modal'
import { useState } from 'react'

interface DistrictProps {
  districts: District[]
  handleSelectDistrict: (id: string) => void
}

const initialState = { id: '', name: '' }

const DistrictStep = ({ districts, handleSelectDistrict }: DistrictProps) => {
  const [selected, setSelected] = useState(initialState)
  return (
    <>
      <Modal
        open={Boolean(selected.id)}
        closeModal={() => setSelected(initialState)}
        actionButtonFN={() => {
          setSelected(initialState)
          handleSelectDistrict(selected.id)
        }}
        actionButtonText="Confirm"
        className="sm:max-w-md"
      >
        <h2 className="text-2xl mb-2 font-semibold">
          Are you sure you want to join the {selected.name}?
        </h2>
        <p>This is irreversible.</p>
      </Modal>
      <h1 className="text-2xl mb-2 font-semibold">Join your district</h1>
      <p className="mb-12 max-w-md">
        Join one of the {districts.length} districts, log any exercise you take
        and your miles will go into your District’s total.
      </p>
      <div className="flex flex-wrap gap-6">
        {districts.map(
          ({ captain, id, name, nbParticipants, nbMilesCovered }) => (
            <button
              onClick={() => {
                setSelected({ id, name })
              }}
              className="flex justify-between bg-white w-full md:max-w-sm shadow sm:rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-primary"
              key={id}
            >
              <div className="py-4 px-4">
                <p className="text-lg font-medium text-primary text-left">
                  {name}
                </p>
                <p className="text-left">Captain: {captain.name}</p>

                <span className="block w-full border-b-2 my-3" />

                <div className="flex">
                  <div className="flex mr-12">
                    <p className="mr-2">{nbParticipants}</p>
                    <img src={PEOPLE} alt="people-icon" />
                  </div>
                  <div className="flex">
                    <p className="mr-2">{nbMilesCovered.toFixed(2)}m</p>
                    <img src={STONKS} alt="chart-icon" />
                  </div>
                </div>
              </div>
              <img
                src={captain.avatar.url}
                alt="captain"
                className="object-cover w-32 h-full"
              />
            </button>
          )
        )}
      </div>
    </>
  )
}

export default DistrictStep
