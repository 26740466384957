import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: 'https://d094be3413f864f7d0c1139f72f63727@o390735.ingest.sentry.io/4506259059769344',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        process.env.REACT_APP_API_URL || '',
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
