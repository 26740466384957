import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { signOut } from '@firebase/auth'
import * as Yup from 'yup'

import FormikInput from '../../../../components/FormikInput'
import Toggle from '../../../../components/Toggle'

import { auth } from '../../../../utils/api/firebase'
import { Taxpayer } from '../../../../utils/reducers/steps'

const taxpayerSchema = Yup.object().shape({
  title: Yup.string().required('Title is a required field.'),
  firstName: Yup.string().required('First Name is a required field.'),
  lastName: Yup.string().required('Last Name is a required field.'),
  postcode: Yup.string().required('Postcode is a required field.'),
  streetName: Yup.string().required('Street Name is a required field.'),
  townOrCity: Yup.string().required('Town is a required field.'),
  country: Yup.string().required('Country is a required field.'),
})

type AddressDetailsProps = {
  onSubmit: (we: any) => Promise<void>
  taxpayer: Taxpayer
  enableCopy: boolean
}

const AddressDetails: React.FC<AddressDetailsProps> = (props) => {
  const [shouldCopyTaxpayer, setShouldCopyTaxpayer] = useState<boolean>(false)

  const initialValues = shouldCopyTaxpayer
    ? {
        title: props.taxpayer.title,
        firstName: props.taxpayer.firstName,
        lastName: props.taxpayer.lastName,
        postcode: props.taxpayer.taxpayerAddress.postcode,
        streetName: props.taxpayer.taxpayerAddress.streetAddress,
        townOrCity: props.taxpayer.taxpayerAddress.city,
        country: props.taxpayer.taxpayerAddress.country,
      }
    : {
        title: '',
        firstName: '',
        lastName: '',
        postcode: '',
        streetName: '',
        townOrCity: '',
        country: '',
      }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={taxpayerSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={props.onSubmit}
      enableReinitialize
    >
      {() => (
        <Form className="space-y-6">
          {props.enableCopy && (
            <Toggle
              title="Use my taxpayer address"
              value={shouldCopyTaxpayer}
              setValue={(val) => setShouldCopyTaxpayer(val)}
            />
          )}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <Field
              type="text"
              name="title"
              component={FormikInput}
              label="Title"
              placeholder="Title"
              autoFocus
            />

            <Field
              type="text"
              name="firstName"
              component={FormikInput}
              label="First Name"
              placeholder="First Name"
            />

            <Field
              type="text"
              name="lastName"
              component={FormikInput}
              label="Last Name"
              placeholder="Last Name"
            />

            <Field
              type="text"
              name="streetName"
              component={FormikInput}
              label="Street name"
              placeholder="Street name"
            />

            <Field
              type="text"
              name="townOrCity"
              component={FormikInput}
              label="Town or city"
              placeholder="Town or city"
            />

            <Field
              type="text"
              name="country"
              component={FormikInput}
              label="Country"
              placeholder="Country"
            />

            <Field
              type="text"
              name="postcode"
              component={FormikInput}
              label="Postcode"
              placeholder="Postcode"
            />
          </div>
          <div className="w-full flex justify-end mt-12">
            <button
              type="button"
              onClick={() => signOut(auth)}
              className="text-error mr-12"
            >
              Logout
            </button>

            <button type="submit" className="w-40 button-primary">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddressDetails
