const steps = [
  {
    name: 'Join your district',
    number: 1,
  },
  {
    name: 'Donate & order snood',
    number: 2,
  },
  {
    name: 'Address details',
    number: 3,
  },
  {
    name: 'Review & pay',
    number: 4,
  },
]
const stepsExtra = [
  {
    name: 'Join your district',
    number: 1,
  },
  {
    name: 'Donate & order snood',
    number: 2,
  },
  {
    name: 'Taxpayer',
    number: 3,
  },
  {
    name: 'Address details',
    number: 4,
  },
  {
    name: 'Review & pay',
    number: 5,
  },
]

interface StepBarProps {
  step: number
  giftAid: boolean
}

const StepBar = ({ step, giftAid }: StepBarProps) => (
  <nav aria-label="Progress" className="mb-12">
    <ul className="space-y-4 md:flex md:space-y-0 md:space-x-8">
      {(giftAid ? stepsExtra : steps).map(({ number, name }) => (
        <li key={name} className="md:flex-1">
          {step > number ? (
            <div className="group pl-4 py-2 flex flex-col border-l-4 border-primary hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
              <span className="text-xs text-primary font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                {number}
              </span>
              <span className="text-sm font-medium">{name}</span>
            </div>
          ) : step === number ? (
            <div
              className="pl-4 py-2 flex flex-col border-l-4 border-primary md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              aria-current="step"
            >
              <span className="text-xs text-primary font-semibold tracking-wide uppercase">
                {number}
              </span>
              <span className="text-sm font-medium">{name}</span>
            </div>
          ) : (
            <div className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
              <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                {number}
              </span>
              <span className="text-sm font-medium">{name}</span>
            </div>
          )}
        </li>
      ))}
    </ul>
  </nav>
)

export default StepBar
