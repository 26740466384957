import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate, useLocation } from 'react-router'

import ShowSteps from './ShowSteps'
import { auth } from '../../utils/api/firebase'

const RequireAuth = () => {
  const [user] = useAuthState(auth)
  const location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />
  }
  return <ShowSteps uid={user.uid} />
}

export default RequireAuth
