import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Field, FieldAttributes } from 'formik'
import { InputHTMLAttributes, ReactNode, useEffect, useRef } from 'react'

interface InputProps {
  field: FieldAttributes<InputHTMLAttributes<HTMLInputElement>>
  label: string
  error?: string
  description?: string
  optional?: boolean
  icon?: ReactNode
  preventActions?: {
    copy?: boolean
    paste?: boolean
  }
}

const Input = ({
  error,
  description,
  icon,
  field: { className = '', ...rest },
  label,
  optional = false,
  preventActions = {
    copy: false,
    paste: false,
  },
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    if (preventActions.copy) {
      inputRef.current.oncopy = (e) => e.preventDefault()
    }
    if (preventActions.paste) {
      inputRef.current.onpaste = (e) => e.preventDefault()
    }
  }, [preventActions])

  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor={rest.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        {optional && (
          <span className="text-sm text-gray-500" id="email-optional">
            Optional
          </span>
        )}
      </div>
      <div className="mt-1 relative">
        <Field
          className={`${
            error ? 'input-text-error' : 'input-text'
          } ${className}`}
          {...rest}
          innerRef={inputRef}
        />

        {icon && !error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )}

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {(error || description) && (
        <p
          className={`mt-2 text-sm ${
            error ? 'text-red-600' : 'text-gray-500'
          } `}
        >
          {error || description}
        </p>
      )}
    </div>
  )
}

export default Input
