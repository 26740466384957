import React from 'react'
import LOGO from '../../assets/logo.png'

interface AuthTemplateProps {
  children: React.ReactNode
  title: string
}

const AuthTemplate = ({ children, title }: AuthTemplateProps) => (
  <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img className="mx-auto h-24 w-auto" src={LOGO} alt="Logo" />
      <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
        {title}
      </h2>
    </div>

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {children}
      </div>
    </div>
  </div>
)

export default AuthTemplate
