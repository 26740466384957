import { District } from '../types'

type Action =
  | { type: 'SET_ERROR'; error: string }
  | { type: 'SET_LOADING'; loading: boolean }
  | { type: 'SET_DISTRICTS'; districts: District[] }
  | { type: 'SET_STEP'; step: number }
  | { type: 'SET_GIFT_AID'; giftAid: boolean }
  | { type: 'SET_TAXPAYER'; taxpayer: Taxpayer }

export interface StepsReducer {
  loading: boolean
  error: string
  districts: District[]
  step: number
  giftAid: boolean
  taxpayer: Taxpayer
}

export interface Taxpayer {
  firstName: string
  lastName: string
  title: string
  taxpayerAddress: {
    country: string
    city: string
    postcode: string
    streetAddress: string
  }
  taxpayer: boolean
}

export const stepsReducer = (state: StepsReducer, action: Action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.loading }
    case 'SET_ERROR':
      return { ...state, error: action.error }
    case 'SET_DISTRICTS': {
      return { ...state, districts: action.districts }
    }
    case 'SET_STEP': {
      return { ...state, step: action.step }
    }
    case 'SET_GIFT_AID': {
      return { ...state, giftAid: action.giftAid }
    }
    case 'SET_TAXPAYER': {
      return { ...state, taxpayer: action.taxpayer }
    }
    default:
      throw new Error('Wrong action type')
  }
}
