import React, { useContext } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import { auth } from '../../utils/api/firebase'

import ToastContext from '../../utils/context/toastContext'

import FormSpinner from '../../components/Settings/FormSpinner'
import SettingsLayout from '../../components/layouts/SettingsLayout'

import ErrorBanner from '../../components/ErrorBanner'
import { useAuthState } from 'react-firebase-hooks/auth'
import { updatePassword } from 'firebase/auth'
import FormikInput from '../../components/FormikInput'

const schema = Yup.object().shape({
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
})

type ResetPasswordTDO = {
  password: string
  confirmPassword: string
}

const initialValues: ResetPasswordTDO = {
  password: '',
  confirmPassword: '',
}

const ResetPassword: React.FC = () => {
  const { setToast } = useContext(ToastContext)
  const [user, loading, error] = useAuthState(auth)

  if (error) {
    return (
      <SettingsLayout title="Personal Details">
        <ErrorBanner
          title={
            error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
              ? 'This session is too old, please close this session and log in again.'
              : 'Something went wrong. Try to refresh!'
          }
        />
      </SettingsLayout>
    )
  }

  if (loading) {
    return (
      <SettingsLayout title="Personal Details">
        <FormSpinner />
      </SettingsLayout>
    )
  }

  const handleOnSubmit = async (
    values: ResetPasswordTDO,
    formikBag: FormikHelpers<ResetPasswordTDO>
  ) => {
    try {
      if (user) {
        await updatePassword(user, values.password)

        setToast({
          status: 'SUCCESS',
          title: 'Success',
          message: 'Password changed successfully',
        })
      }
    } catch (e) {
      setToast({
        status: 'ERROR',
        title: 'Error',
        message: 'Something went wrong. Try again!',
      })
    }
  }

  return (
    <SettingsLayout title="Personal Details">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<ResetPasswordTDO>) => (
          <Form>
            <div className="flex flex-col max-w-sm gap-6">
              <Field
                type="password"
                name="password"
                component={FormikInput}
                label="Password"
                autoFocus
              />
              <Field
                type="password"
                name="confirmPassword"
                component={FormikInput}
                label="Confirm password"
                autoFocus
              />

              <button
                type="submit"
                className="button-primary mt-6"
                disabled={isSubmitting || !dirty}
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SettingsLayout>
  )
}

export default ResetPassword
