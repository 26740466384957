import DashboardTemplate from '../../components/DashboardTemplate'
import HEART from '../../assets/icons/heart.svg'
import RUNNING from '../../assets/icons/running.svg'
import { useDocument } from 'react-firebase-hooks/firestore'
import { userRef } from '../../utils/api/firebase/functions/user'
import Spinner from '../../components/Spinner'
import { UserData } from '../../utils/types'
import ErrorBanner from '../../components/ErrorBanner'
import { useContext, useReducer } from 'react'
import Modal from '../../components/Modal'
import { myProgressReducer, Units } from '../../utils/reducers/myProgress'
import { API } from '../../utils/api/endpoints'
import axios from 'axios'
import UserTokenContext from '../../utils/context/UserTokenContext'
import ToastContext from '../../utils/context/toastContext'

interface DashboardProps {
  uid?: string
}

const initialState = {
  loading: false,
  error: '',
  progress: 0,
  unit: 'KM' as Units,
  open: false,
  activityType: 'Running',
}

const Dashboard = ({ uid }: DashboardProps) => {
  const [
    { open, unit, progress, error: progressError, activityType },
    dispatch,
  ] = useReducer(myProgressReducer, initialState)
  const [user, loading, error] = useDocument(userRef(uid || ''))
  const { token } = useContext(UserTokenContext)
  const { setToast } = useContext(ToastContext)

  if (loading) return <Spinner />

  const { nbActivities, nbMilesCovered } = user?.data() as UserData

  const handleSubmit = async () => {
    try {
      const transformUnit =
        unit === 'KM' ? (progress * 0.621371192).toFixed(2) : progress

      if (progressError) {
        dispatch({ type: 'SET_ERROR', error: '' })
      }

      if (!progress) {
        dispatch({ type: 'SET_ERROR', error: 'Please enter a number.' })
        return
      }

      if (transformUnit > 150) {
        dispatch({
          type: 'SET_ERROR',
          error: 'Distance should be less than 150 miles.',
        })
        return
      }

      await axios({
        method: 'POST',
        url: `${API.activities}`,
        data: {
          nbMiles: transformUnit,
          type: activityType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setToast({
        status: 'SUCCESS',
        title: 'SUCCESS',
        message: 'Activity added.',
      })

      dispatch({ type: 'SET_OPEN', open: false })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    }
  }

  return (
    <DashboardTemplate title="My Progress">
      <>
        <Modal
          open={open}
          closeModal={() => dispatch({ type: 'SET_OPEN', open: false })}
          actionButtonFN={handleSubmit}
          actionButtonText="Log activity"
          className="sm:max-w-sm"
        >
          <h2 className="text-2xl mb-2 font-semibold">
            Manually add your activity
          </h2>
          <p>
            Any activity counts, whether you are running a marathon or just
            walking the dog, it all contributes to your districts total.
          </p>
          <div className="flex mt-6 max-w-md">
            <input
              className="input-text rounded-tr-none rounded-br-none focus:border-gray-300 focus:ring-transparent"
              type="number"
              onChange={(e) =>
                dispatch({
                  type: 'SET_PROGRESS',
                  progress: Number(e.target.value),
                })
              }
            />
            <button
              onClick={() => dispatch({ type: 'SET_UNITS', unit: 'MI' })}
              className={`border w-12 focus:outline-none border-gray-300 border-l-0 border-r-0 ${
                unit === 'MI' ? 'bg-yellow-500' : ''
              }`}
            >
              mi
            </button>
            <button
              onClick={() => dispatch({ type: 'SET_UNITS', unit: 'KM' })}
              className={`border w-12 focus:outline-none  border-gray-300 rounded-tr-md rounded-br-md ${
                unit === 'KM' ? 'bg-yellow-500' : ''
              }`}
            >
              km
            </button>
          </div>
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700 mt-3"
            >
              Activity Type
            </label>
            <select
              id="type"
              name="type"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue="Running"
            >
              {[
                'Running',
                'Walking',
                'Cycling',
                'Swimming',
                'Rowing',
                'Weight Lifting',
                'Cardio',
                'Yoga',
                'Martial Arts',
                'Snowsports',
                'Dog Walking',
              ].map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {progressError && <ErrorBanner title={progressError} />}
        </Modal>

        {error && <ErrorBanner title={error.message} />}
        <div className="flex w-full justify-end">
          <button
            onClick={() => dispatch({ type: 'SET_OPEN', open: true })}
            className="button-primary"
          >
            Log exercise manually
          </button>
        </div>
        <div className="h-full flex flex-col justify-center items-center gap-12">
          {!nbActivities && !nbMilesCovered ? (
            <p className="text-xl font-bold text-primary">
              Not activities yet.
            </p>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center gap-2">
                <img className="w-16" src={HEART} alt="" />
                <p className="text-2xl">Total workouts</p>
                <p>
                  <span className="text-xl font-bold">{nbActivities}</span>{' '}
                  sessions
                </p>
              </div>

              <div className="flex flex-col justify-center items-center gap-2">
                <img className="w-16" src={RUNNING} alt="" />
                <p className="text-2xl">Distance covered</p>
                <p>
                  <span className="text-xl font-bold">
                    {nbMilesCovered.toFixed(2)}
                  </span>{' '}
                  Miles
                </p>
              </div>
            </>
          )}
        </div>
      </>
    </DashboardTemplate>
  )
}

export default Dashboard
